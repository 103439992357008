<template>
  <div class="CV">
    <h1>Om mig</h1>
    <img alt="Steffen" src="../assets/steffen.png">
    <div>
      <p>Mine foretrukne materialer er granit, bronze og ler.</p>
      <p>Udgangspunktet for mit formsprog er oftes mennesket, hvorfra jeg abstraherer i et ekspressivt udtryk.<br/></p>
      <p>Desuden arbejder jeg også med nonfigurative organiske former.<br/></p>
      <p>Som autodidakt billedhugger arbejdede jeg først med materialer som træ, ler, gips og jern. Granitten kom først
        ind i billedet i 1969. Siden midt i 80 har jeg også arbejdet i bronze.<br/></p>
      <p>Medlem af :<br/></p>
      <p>Billedkunstnernes Forbund.<br/></p>
      <p>Dansk Billedhuggersamfund.<br/></p>
      <p>Kunstergruppen Sten &amp; Papir.</p>
    </div>

    <h2>Udstillinger</h2>
    <p
        v-for="a in exhibitions"
        :key="a"
    >
      {{ a.year }} {{ a.venue }}
    </p>


    <h2>Salg til offentlige</h2>
    <p
        v-for="a in public_sales"
        :key="a"
    >
      {{ a }}
    </p>
  </div>



</template>

<script>

export default {
  name: "CV",
  data: () => ({
    exhibitions: [

      {
        year: "1982-83",
        venue: "KUNSTNERNES PÅSKEUDSTILLING ÅRHUS"
      },
      {
        year: "1982-86-2001",
        venue: "SKOVHUSET VÆRLØSE"
      },
      {
        year: "1982-86",
        venue: "VETLANDA MUSEUM SVERIGE"
      },
      {
        year: "1982",
        venue: "GALLERI BORGEN ÅRHUS"
      },
      {
        year: "1982",
        venue: "CAFE GALLERIET SVENDBORG"
      },
      {
        year: "1984",
        venue: "GALLERI TO BORNHOLM"
      },
      {
        year: "1984",
        venue: "TUBORG KUNSTFORENING"
      },
      {
        year: "1984",
        venue: "ODENSE KUNSTBYGNING"
      },
      {
        year: "1984",
        venue: "GALLERI TI, TISTRUP"
      },
      {
        year: "1984",
        venue: "KONGENS HAVE, KØBENHAVN"
      },
      {
        year: "1984-85-87-88",
        venue: "ELMEGÅRDEN, HEDEHUSENE"
      },
      {
        year: "1985",
        venue: "BIRKERØD KUNSTFORENING"
      },
      {
        year: "1985-91-97",
        venue: "BILLEDVÆRKSTEDET, BROVST"
      },
      {
        year: "1985-86-87",
        venue: "DEN FRIE, KØBENHAVN"
      },
      {
        year: "1988",
        venue: "KILDEPARKEN, GLADSAXE"
      },
      {
        year: "1990",
        venue: "JÆGERSPRIS SLOTSPARK"
      },
      {
        year: "1991",
        venue: "FREDERIKSSUND KUNSTFORENING"
      },
      {
        year: "1992",
        venue: "VÆRLØSE BYMIDTE"
      },
      {
        year: "1993-94",
        venue: "LANGES MAGASIN FR.SUND"
      },
      {
        year: "1995",
        venue: "JÆGERSPRIS SLOT"
      },
      {
        year: "1995",
        venue: "ÆGLAGERET HOLBÆK"
      },
      {
        year: "1995",
        venue: "FR.VÆRK KUNSTFORENING"
      },
      {
        year: "1996 -97",
        venue: "LANGES MAGASIN FR.SUND"
      },
      {
        year: "1998",
        venue: "SLANGERUP KOMMUNE"
      },
      {
        year: "1998",
        venue: "COBRA RUMMET SOFIEHOLM"
      },
      {
        year: "1998",
        venue: "KØBENHAVN FR.BERG"
      },
      {
        year: "1999",
        venue: "PAKHUSET NYKØBING"
      },
      {
        year: "1999",
        venue: "GALLERI REFF"
      },
      {
        year: "1999",
        venue: "EKSØ KUNSTFORENING SVERIGE"
      },
      {
        year: "2000",
        venue: "ÅGALLERIET FR. VÆRK"
      },
      {
        year: "2002",
        venue: "GALLERI SKABELSBJERG"
      },
      {
        year: "2004",
        venue: "KULTURHUSET FARUM"
      },
      {
        year: "2004",
        venue: "KONGENS HAVE KBH"
      },
      {
        year: "2005-10-26",
        venue: "SØLYST SKULPTUR JYDERUP"
      },
      {
        year: "2005",
        venue: "VÆRKET FR.SUND KUNSTFORENING"
      },
      {
        year: "2005",
        venue: "KØGE KUNSTFORENING"
      }

    ],
    public_sales: [
      "KØBENHAVNS KOMMUNES KULTURFOND.",
      "VETLANDA KUNST MUSEUM, SVERIGE.",
      "VÆRLØSE KOMMUNE.",
      "ROSKILDE KOMMUNE.",
      "KILDEPARKEN BOLIGFORENING GLADSAXE.",
      "SOGNESKOLEN JÆGERSPRIS.",
      "JÆGERSPRIS KOMMUNES BØRNEHAVE.",
      "FREDRIKSSUND KOMMUNE.",
      "SKULDELEV KIRKE ( DØBEFOND)",
      "SELSØ KIRKE",
    ]
  })

}
</script>


<style scoped>

</style>